// Stylesheets
import './theme.scss';

// Resources
import './resources/images/favicon.gif';

// Scripts
import '@oecd/util-site/site.js';

import '@oecd/util-site/scroll-interactions.js';
import '@oecd/util-site/scroll-bar-width.js';
import '@oecd/util-site/tab-interaction.js';
import '@oecd/util-horizontal-scroll/horizontal-scroll.js';

import '@oecd/component-charts-v1/charts.js';
import '@oecd/component-embed-v1/embed.js';
import '@oecd/component-descriptive-asset-v1/descriptive-asset.js';
import '@oecd/component-content-language-picker-v1/content-language-picker.js';
import '@oecd/component-footnote-v1/footnote.js';
import '@oecd/component-form-v1/form.js';
import '@oecd/component-jumplinks-v1/jumplinks.js';
import '@oecd/component-language-switch-v1/language-switch.js';
import '@oecd/component-list-v1/list.js';
import '@oecd/component-main-navigation-v1/main-navigation.js';
import '@oecd/component-overlay-v1/overlay.js';
import '@oecd/component-report-header-v1/report-header.js';
import '@oecd/component-report-navigation-v1/report-navigation.js';
import '@oecd/component-report-navigation-v1/report-navigation-mobile.js';
import '@oecd/component-search-app-v1/search-app.js';
import '@oecd/component-search-tabs-v1/search-tabs.js';
import '@oecd/component-searchbox-v1/searchbox.js';
import '@oecd/component-searchbox-dialog-v1/searchbox-dialog.js';
import '@oecd/component-secondary-navigation-v1/secondary-navigation.js';
import '@oecd/component-subscribe-banner-v1/subscribe-banner.js';
import '@oecd/component-table-v1/table.js';
import '@oecd/component-tabs-list-v1/tabs-list.js';
import '@oecd/component-title-v1/title.js';

// Analytics
import '@oecd/component-page-v1/google-analytics.js';

import '@oecd/util-site/site-init.js';
