import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {
    'use strict';

    const formSubmitButtonSelector = '.cmp-form-button[type="submit"]';
    let isWidgetExecuted = false;
    const TIMEOUT = 500;

    function toggleSubmitButton(submitButton, isEnabled, displayStyle = '') {
        if (submitButton) {
            submitButton.disabled = !isEnabled;
            submitButton.style.display = displayStyle;
        }
    }

    function setupTurnstileRendering(form, submitButton, turnstileElement) {
        turnstile.render(turnstileElement, {
            callback(token) {
                HTMLFormElement.prototype.submit.call(form);
            },
            'error-callback'() {
                toggleSubmitButton(submitButton, true, '');
                return false;
            }
        });
    }

    function isInViewport(domElement) {
        return new Promise(resolve => {
            const observer = new IntersectionObserver(([entry]) => {
                // Check if the element is at least partially in the viewport
                resolve(entry.isIntersecting);
                observer.disconnect();
            });
            observer.observe(domElement);
        });
    }

    function addFormSubmitListener(form, submitButton, turnstileElement) {
        submitButton.addEventListener('click', async (event) => {
            if (!form.checkValidity()) {
                const firstInvalidField = form.querySelector(':invalid');
                // Added logic to ensure required message shows when the invalid field is not in the viewport
                if (firstInvalidField) {
                    const isVisible = await isInViewport(firstInvalidField);
                    if (!isVisible) {
                        event.preventDefault();
                        setTimeout(() => {
                            firstInvalidField.reportValidity();
                        }, TIMEOUT);
                    }
                }
                return;
            }

            if (isWidgetExecuted) {
                turnstile.reset(turnstileElement);
            }

            isWidgetExecuted = true;

            turnstile.execute(turnstileElement);
            toggleSubmitButton(submitButton, false, 'none');
        });
    }

    function initializeFormHandling() {
        [...document.querySelectorAll('form.js-form')].forEach((form) => {
            const turnstileElement = form.querySelector('.cmp-turnstile');
            const submitButton = form.querySelector(formSubmitButtonSelector);

            if (turnstileElement && submitButton) {
                addFormSubmitListener(form, submitButton, turnstileElement);
                setupTurnstileRendering(form, submitButton, turnstileElement);
            }
        });
    }

    onDomReady(() => {
        if (typeof turnstile !== 'undefined') {
            turnstile.ready(initializeFormHandling);
        }
    });

}());
