/* eslint-disable */
import {urlIsAbsolute} from '@oecd/util-site/url';

(function (Site) {

    'use strict';

    const CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_PREFIX = 'oecdcontrol';
    const CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_DELIMITER = '-';

    const chartControlParamsMapFromUrl = () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const urlParams = new URLSearchParams(url.search);
        const params = new Map();
        for (const [key, value] of urlParams) {
            if (key.startsWith(CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_PREFIX + CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_DELIMITER)) {
                const urlSubParams = key.split(CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_DELIMITER);
                const controlId = urlSubParams[1];
                const variableName = urlSubParams[2];
                params.set(controlId, {
                    name: variableName,
                    value
                });
            }
        }
        return params;
    };

    const updateChartControlFromUrl = (params, controlElement) => {
        const controlId = controlElement.getAttribute('control-id');
        const controlVariable = params.get(controlId);
        if (controlVariable && controlVariable['name'] && controlVariable['value']) {
            controlElement.setAttribute(controlVariable['name'], controlVariable['value']);
        }
    };

    const updateCurrentUrlWithChartControlDetail = (controlId, variableName, variableValue) => {
        if (!controlId) {
            return;
        }

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const urlParams = new URLSearchParams(url.search);
        const paramName = [CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_PREFIX, controlId, variableName]
            .join(CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_DELIMITER);

        if (variableValue) {
            urlParams.set(paramName, variableValue);
        } else {
            urlParams.delete(paramName);
        }

        window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
    };

    const memoizeGetChartsByControl = () => {
        const cache = {};
        return (controlElement) => {
            if (controlElement.controlId in cache) {
                return cache[controlElement.controlId];
            }

            let charts = [];

            if (controlElement.hasAttribute('data-chart-component-ids')) {
                const chartComponentIds = controlElement.getAttribute('data-chart-component-ids').split(',');
                [...chartComponentIds].forEach(chartComponentId => {
                    charts = charts.concat([...document.querySelectorAll(`oecd-chart[data-component-id="${chartComponentId}"]`)]);
                });
            } else {
                charts = charts.concat([...document.querySelectorAll('oecd-chart')]);
            }

            cache[controlElement.controlId] = charts;
            return charts;
        };
    };

    document.addEventListener('cbControlValueChange', ({detail}) => {
        const getChartsByControl = memoizeGetChartsByControl();
        const controlElements = document.querySelectorAll(`oecd-control[data-component-id="${detail.dataComponentId}"]`);
        [...controlElements].forEach(controlElement => {
            const chartElements = getChartsByControl(controlElement);
            chartElements.map((chart) => {
                chart.setAttribute(
                    detail.varName === 'chartId' ? 'chart-id' : detail.varName,
                    detail.varValue
                );
            });

            if (!detail.isInitialChange) {
                if (detail.varValue) {
                    updateCurrentUrlWithChartControlDetail(detail.controlId, detail.varName, detail.varValue);
                } else {
                    updateCurrentUrlWithChartControlDetail(detail.controlId, detail.varName);
                }
            }
        });
    });

    document.addEventListener('chartWebComponentsDependenciesLoaded', () => {
        const chartControls = document.querySelectorAll('oecd-control');
        const urlParams = chartControlParamsMapFromUrl();

        [...chartControls].forEach(chartControl => {
            if (urlParams.size > 0) {
                updateChartControlFromUrl(urlParams, chartControl);
            }
        });
    });

    const addChartControlParamsToUrl = (link) => {
        const href = link.getAttribute('href');
        if (href !== null && href !== '' && href !== '#') {
            const currentUrl = new URL(window.location.href);
            const currentUrlParams = new URLSearchParams(currentUrl.search);
            const nextUrl = urlIsAbsolute(href) ? new URL(href) : new URL(href, window.location.origin);
            for (const [key, value] of currentUrlParams) {
                if (key.startsWith(CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_PREFIX + CHART_CONTROL_URL_QUERY_PARAM_VARIABLE_NAME_DELIMITER)) {
                    nextUrl.searchParams.set(key, value);
                }
            }
            link.setAttribute('href', nextUrl.toString());
        }
    };

    const initializeChartControlQueryParams = (context) => {
        const links = context.querySelectorAll('[data-add-chart-control-query-param]');
        [...links].forEach(link => {
            link.addEventListener('click', () => {
                addChartControlParamsToUrl(link);
            });
        });
    };

    Site.behaviors.chartControl = {
        attach(context) {
            initializeChartControlQueryParams(context);
        }
    };

}(Site));
