(function(Site) {

    'use strict';

    let dataLayerEnabled;
    let dataLayer;

    function getComponentData(element) {
        const dataLayerJson = element.dataset.cmpGaDataLayer;
        return dataLayerJson ? JSON.parse(dataLayerJson) : {};
    }

    function handleClickEvent(event) {
        const clickedElement = event.currentTarget;
        const href = clickedElement.getAttribute('href');
        const componentData = getComponentData(clickedElement);
        if (href) {
            event.preventDefault();
            localStorage.setItem('clickedEvent', JSON.stringify(componentData));
            window.location = href;
        } else {
            dataLayer.push(componentData);
        }
    }

    function cleanUpLocalStorage() {
        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key) && key.includes('uniqueTracking_')) {
                localStorage.removeItem(key);
            }
        }
    }

    function trackUniqueClick(event) {
        const clickedElement = event.currentTarget;

        if (!localStorage.getItem(`uniqueTracking_${clickedElement.id}`)) {
            const componentData = getComponentData(clickedElement);

            if (componentData) {
                dataLayer.push(componentData);
                localStorage.setItem(`uniqueTracking_${clickedElement.id}`, true);
            }
        }
    }

    function attachUniqueClickEventListener(element) {
        cleanUpLocalStorage();
        element.addEventListener('click', trackUniqueClick);
    }

    function trackPageLoad(element) {
        const pageLoadData = getComponentData(element);
        dataLayer.push(pageLoadData);
    }

    function attachListeners(clickableElements, uniqueClickableElements) {
        const uniqueElementsArray = [...uniqueClickableElements];
        [...clickableElements].forEach(element => {
            if (!uniqueElementsArray.includes(element)) {
                element.addEventListener('click', handleClickEvent);
            }
        });
        [...uniqueElementsArray].forEach(attachUniqueClickEventListener);
    }

    function sendPendingClickEvent() {
        const componentData = localStorage.getItem('clickedEvent');
        if (componentData) {
            dataLayer.push(JSON.parse(componentData));
            localStorage.removeItem('clickedEvent');
        }
    }

    function initializeGoogleAnalytics(context) {
        dataLayerEnabled = document.body.hasAttribute('data-ga-layer-enabled');
        dataLayer = dataLayerEnabled ? window.dataLayer = window.dataLayer || [] : {};

        if (dataLayerEnabled) {
            const clickableElements = context.querySelectorAll('[data-cmp-ga-clickable]');
            const uniqueClickableElements = context.querySelectorAll('[data-cmp-ga-unique-tracking]');
            const pageLoads = context.querySelectorAll('[data-cmp-ga-page-load]');

            attachListeners(clickableElements, uniqueClickableElements);

            if (pageLoads !== null) {
                pageLoads.forEach((pageLoad) => {
                    trackPageLoad(pageLoad);
                });
            }
            sendPendingClickEvent();
        }
    }

    Site.behaviors.googleAnalytics = {
        attach(context) {
            initializeGoogleAnalytics(context);
        }
    };

}(Site));
